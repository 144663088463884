import React from 'react';
import {Fragment} from 'react';
import {HeaderOne} from '../Header';
import {FooterTwo} from '../Footer';
import LoadingOverlay from 'react-loading-overlay-ts';
import {connect} from 'react-redux';

const LayoutTwo = ({children, aboutOverlay, footerBgClass, subscribeEnable, shopButtons, commonData}) => {
  const {spinner} = commonData;

  return (
    <Fragment>
      <HeaderOne aboutOverlay={aboutOverlay} shopButtons={shopButtons} spinner={spinner} />
      <LoadingOverlay
        active={spinner}
        text='Loading...'
        spinner={true}
        styles={{
          spinner: (base) => ({
            ...base,
            width: '100px',
          }),
          content: () => ({
            top: '50%',
            left: '50%',
            position: 'fixed',
          }),
          wrapper: (base) => ({
            ...base,
            // position: 'static',
          }),
          overlay: (base) => ({
            ...base,
            zIndex: '10000000 !important',
          }),
        }}>
        <>
          {children}
          <FooterTwo footerBgClass={footerBgClass} subscribeEnable={subscribeEnable} />
        </>
      </LoadingOverlay>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    commonData: state.commonData,
  };
};

export default connect(mapStateToProps, null)(LayoutTwo);
