import React from 'react';
import {useState, useEffect} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter} from 'react-icons/fa';
import {IoIosArrowRoundUp} from 'react-icons/io';
import {animateScroll} from 'react-scroll';
import {SubscribeEmailTwo} from '../Newsletter';
import Link from 'next/link';
import clsx from 'clsx';
import Image from 'next/image';

const FooterTwo = ({footerBgClass, subscribeEnable = true}) => {
  const [scroll, setScroll] = useState(0);
  const [top, setTop] = useState(0);

  useEffect(() => {
    setTop(100);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  return (
    <footer className={`space-pt--100 space-pb--50 ${footerBgClass ? footerBgClass : 'bg-color--grey'}`}>
      <Container className='wide'>
        <Row>
          <Col className='footer-single-widget space-pb--15 ps-5'>
            {/* logo */}
            <div className='d-none d-md-block'>
              <div className='logo space-mb--35 position-relative footer-logo'>
                {/*<img*/}
                {/*  src={*/}
                {/*    process.env.NEXT_PUBLIC_URL + footerBgClass === 'bg-color--blue-two'*/}
                {/*      ? '/assets/images/logo-alt.png'*/}
                {/*      : '/assets/images/logo.png'*/}
                {/*  }*/}
                {/*  className='img-fluid'*/}
                {/*  alt=''*/}
                {/*/>*/}

                <Image src='/layout/logo' alt='ValetPress, Inc' width={162} height={55} layout='responsive' />
              </div>
            </div>

            {/*=======  copyright text  =======*/}
            <div className='footer-single-widget__copyright'>
              &copy; {new Date().getFullYear() + ' '}
              <a href='https://valetpress.com'>ValetPress, Inc</a>
              <span>All Rights Reserved</span>
            </div>
          </Col>

          <Col className='footer-single-widget space-mb--50 ps-5 d-none d-md-block'>
            <h5 className='footer-single-widget__title'>ABOUT</h5>
            <nav className='footer-single-widget__nav'>
              <ul>
                <li>
                  <Link href='/about-us/'>
                    <a>About Us</a>
                  </Link>
                </li>
                <li>
                  <Link href='/privacy-policy/' as={process.env.NEXT_PUBLIC_URL + '/privacy-policy/'}>
                    <a>Privacy Policy</a>
                  </Link>
                </li>
                <li>
                  <Link href='/terms-and-conditions/' as={process.env.NEXT_PUBLIC_URL + '/terms-and-conditions/'}>
                    <a>Terms & Conditions</a>
                  </Link>
                </li>
                <li className='position-relative'>
                  <Link href='/faq/' as={process.env.NEXT_PUBLIC_URL + '/faq/'}>
                    <a>FAQ</a>
                  </Link>
                </li>
                <li>
                  <Link href='/contact-us/' as={process.env.NEXT_PUBLIC_URL + '/contact-us/'}>
                    <a>Contact Us</a>
                  </Link>
                </li>
              </ul>
            </nav>
          </Col>

          <Col className='footer-single-widget space-mb--50 ps-5 d-none d-md-block'>
            <h5 className='footer-single-widget__title'>OUR SERVICES</h5>
            <nav className='footer-single-widget__nav'>
              <ul>
                <li>
                  <Link
                    href='/services/business-apparel/'
                    as={process.env.NEXT_PUBLIC_URL + '/services/business-apparel/'}>
                    <a>Business Apparel</a>
                  </Link>
                </li>
                <li>
                  <Link
                    href='/services/laundry-service/'
                    as={process.env.NEXT_PUBLIC_URL + '/services/laundry-service/'}>
                    <a>Laundry Service</a>
                  </Link>
                </li>
                <li>
                  <Link href='/services/dry-cleaning/' as={process.env.NEXT_PUBLIC_URL + '/services/dry-cleaning/'}>
                    <a>Dry Cleaning</a>
                  </Link>
                </li>
                <li>
                  <Link
                    href='/services/pickup-delivery/'
                    as={process.env.NEXT_PUBLIC_URL + '/services/pickup-delivery/'}>
                    <a>Pickup & Delivery</a>
                  </Link>
                </li>
                <li>
                  <Link href='/services/lease/' as={process.env.NEXT_PUBLIC_URL + '/services/lease/'}>
                    <a>Garment Lease Program</a>
                  </Link>
                </li>
                <li>
                  <Link
                    href='/services/embroidered-apparel/'
                    as={process.env.NEXT_PUBLIC_URL + '/services/embroidered-apparel/'}>
                    <a>Embroidered Apparel</a>
                  </Link>
                </li>
              </ul>
            </nav>
          </Col>

          <Col className='footer-single-widget space-mb--50  ps-5 d-none d-md-block'>
            <h5 className='footer-single-widget__title'>FOLLOW US ON</h5>
            <nav className='footer-single-widget__nav footer-single-widget__nav--social'>
              <ul>
                <li>
                  <a href='https://twitter.com/valetpressinc' target='_blank' rel='noreferrer'>
                    <FaTwitter /> Twitter
                  </a>
                </li>
                <li>
                  <a href='https://www.facebook.com/ValetPressFB/' target='_blank' rel='noreferrer'>
                    <FaFacebookF /> Facebook
                  </a>
                </li>
                <li>
                  <a href='https://www.instagram.com/valetpressinc/'>
                    <FaInstagram /> Instagram
                  </a>
                </li>
                <li>
                  <a href='https://www.linkedin.com/company/valetpress/'>
                    <FaLinkedinIn /> LinkedIn
                  </a>
                </li>
              </ul>
            </nav>
          </Col>

          <Col
            className={clsx('footer-single-widget space-mb--50 ps-5 pe-5 pe-md-0 pe-lg-2 d-none', {
              'd-md-block': !subscribeEnable,
            })}>
            <div className='footer-subscribe-widget'>
              <h2 className='footer-subscribe-widget__title'>Subscribe</h2>
              <p className='footer-subscribe-widget__subtitle'>
                Subscribe to our newsletter to receive news and updates.
              </p>
              {/* email subscription */}
              <SubscribeEmailTwo mailchimpUrl='https://valetpress.us2.list-manage.com/subscribe/post?u=14ebf06226a4375ce2fe0c82e&amp;id=6304347dd4' />
            </div>
          </Col>
        </Row>
      </Container>
      <button
        className={`d-flex flex-column justify-content-center align-items-center scroll-top ${
          scroll > top ? 'show' : ''
        }`}
        onClick={() => scrollToTop()}>
        <IoIosArrowRoundUp />
      </button>
    </footer>
  );
};

export default FooterTwo;
